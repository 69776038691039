// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$skipli-header-height: 50px;

.overlay {
  top: 0;
  z-index: 999;
}

.modalClassname {
  height: 100vh;
  width: 100vw;
}

.contentContainer {
  height: auto;
  position: relative;
}

.warningModalModalBox {
  height: 410px;
  width: 600px;
}

.domainContainer {
  border-bottom: 1px solid $border-color;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.publishWebsiteSection {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 2rem;
  }
}

.whatsnext {
  margin: 1.4rem 0 1rem 0;
  text-align: center;
  text-transform: uppercase;
}

.heading {
  font-size: 1.2rem;
  margin: 0 0 0.4rem 0;
}

.publishNowBtn {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $primary;
  color:  $primary;
  margin-top: 14px;
  padding: 0 0 2px 0;
  text-decoration: none;
}

.warningButtons {
  display: flex;
}

.button {
  border-radius: 100px;
  margin-left: 0;
}

.saveAndExitBtn {
  @extend .button;
  font-weight: bold;
  margin-right: 1rem;
  padding: 12px 30px;
}

.exitBtn {
  @extend .button;
  padding: 12px 16px;
}
