// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.subNavbarLeftColumn {
  width: 45%;
  & > h1 {
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: inherit;
  }
}

.subNavbarRightColumn {
  display: flex;
  justify-content: flex-end;
  width: 55%;
}

.button {
  margin: 0;
}

.editButton {
  @extend .button;
  padding: 8px 20px;
  & > svg {
    fill: #fff;
    height: 14px;
    margin-right: 8px;
    width: 14px;
    & > path {
      fill: #fff !important;
    }
  }
}

.saveButton {
  @extend .button;
  color: $primary;
  margin-left: 16px;
  padding: 8px 30px;
  & > svg {
    height: 14px;
    margin-right: 8px;
    width: 14px;
    & > path {
      stroke: $primary !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .button {
    font-size: 0.8rem;
  }

  .editButton {
    padding: 8px 10px;
    & > svg {
      height: 14px;
      width: 14px;
    }
  }

  .saveButton {
    padding: 8px 18px;
  }
}
