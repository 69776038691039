// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~components/WebPageEditWindow/style.module.scss";

$website-header-height: 80px;
$website-header-mobile-height: 56px;

.container {
  padding-top: ($skipli-header-height + $website-header-height);
}

@media only screen and (max-width: 700px) {
  .container {
    padding-top: ($skipli-header-height + $website-header-mobile-height);
  }
}
