// BUTI DINERS, INC. All right Reserved ©

$global-border-radius: 8px;
$global-font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Helvetica Neue", "Ubuntu", "sans-serif";
$skipli-red: #eb1700;

// Color
$intro-background: #f5fdf9;

$primary: #006AFF;
$primary-light: #def2f1;
$primary-lightest: #f2ffff;

$black: #000;
$black-light: #0b2135;
$white: #fff;
$yellow: #ffc555;

$border-color: #e0e5fd;
$border-color-dark: #d4dae0;

// Text
$text-color: #000;
$text-color-light: #222222;
$text-color-alt: #051146;
$text-color-alt-light: #26305d;

$info: #006AFF;
$danger: #fd5458;
$success: #009459;
$warning: #fc8c32;
$white: #fff;
$black: #000;
$grey: #e4e6eb;
$dark-grey: #525f7f;
$grey-medium: #586271;

// Grey
$grey1: #e4e6eb;
$grey2: #d4dae0;
$grey3: #f7f7f7;
$grey4: #f4f5f6;
$grey5: #f6f8fa;
$grey6: #ebeef1;
$grey7: #f9fbfd;
$grey8: #f0f2f6;

// Transition
$transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
$transition1: transform 0.2s;
$transition2: all 100ms ease-in-out 0s;

// Shadows
$button-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
$shadow: 0 0 30px 3px #e6e6e6;
$shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.16);
$shadow2: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$shadow-emphasize: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
  rgba(65, 155, 249, 0) 0px 0px 0px 0px,
  rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
  rgba(159, 159, 164, 0.25) 0px 2px 10px 0px;
